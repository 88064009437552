<template>
  <div class="first-forum-detail">
    <br>
    <BTitle :text="forumInfo[thread].title"></BTitle>
    <div class="first-forum-content center">
      <div v-if="forumInfo[thread].desc" class="desc" v-html="forumInfo[thread].desc"></div>
      <br>
      <div class="live">
        <video class="video" controls :poster="forumInfo[thread].videoPoster? forumInfo[thread].videoPoster : '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E8%A7%86%E9%A2%91/2018/videoPoster.jpg'" :src="forumInfo[thread].video"></video>
      </div>
      <br>
      <DTitle  v-if="forumInfo[thread].guests && forumInfo[thread].guests.length > 0" style="margin:0 8px;" text="演讲嘉宾" :tip="thread!=0? '以下嘉宾按论坛演讲出场顺序排列。':''"/>
      <br>
      <div class="flex guests-wrap">
        <div v-for="(item, index) in forumInfo[thread].guests" :key="index" class="tc guest">
          <div class="img">
            <img :src="forumInfo[thread].imgBase+(item[0].replace(/\s+/g,''))+(thread>6? '.jpg':'.png') + '?x-oss-process=image/resize,m_fill,h_460,w_396'">
            <div class="name">
              <p v-html="item[1]"></p>
            </div>
          </div>
          <h3>{{item[0]}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      thread: this.$route.query.thread,
      forumInfo: [
        {
          title: '开幕式暨主旨论坛',
          guests: [
            ['翁孟勇','第十三届全国人大常委会<br>委员<br>中国公路学会理事长','致辞'],
            ['高兴夫','浙江省人民政府副省长','致辞'],
            ['陈利幸','浙江省交通运输厅厅长','主持人'],
            ['洪晓枫','交通运输部科技司副司长','致辞'],
            ['王 敏','杭州市萧山区委副书记、区长，现任杭州市高新区党工委、滨江区委书记。','致辞'],
            ['傅志寰','中国工程院院士<br>原铁道部部长<br>俄罗斯交通科学院院士','关于中国交通运输发展若干认识'],
            ['李德毅','中国工程院院士','未来汽车：学习型的轮式机器人'],
            ['Peter Muller','国际先进交通协会(ATRA)主席','智慧城市和无人驾驶']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/主旨论坛/论坛视频.mp4',
          desc: '开幕式暨主旨论坛于2018年12月21日上午9:30-11:30在杭州国际博览中心4F大会议厅成功举办。主旨论坛以“交通产业 智引未来”为主题，由浙江省交通运输厅、浙江省经济和信息化厅、杭州市萧山区人民政府联合主办，交通运输部公路科学研究院、中国公路学会、清华大学联合支持。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/主旨论坛/嘉宾照片/',
        },
        {
          title: '培育综合交通产业 • 打造交通强国示范区论坛',
          guests: [
            ['陈利幸','浙江省交通运输厅厅长','致辞'],
            ['石耀东','国务院发展研究中心产业经济部副部长、研究员','交通产业经济发展'],
            ['谭小平','招商局交通物流事业部多式联运创新发展中心总监','现代物流'],
            ['岑晏青','交通运输部公路科学研究院副院长兼总工程师','推动智慧公路与自动驾驶创新融合发展'],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/培育综合交通产业论坛/论坛视频.mp4',
          desc: '培育综合交通产业·打造交通强国示范区论坛于12月21日下午15:30-18:00在杭州国际博览中心3A-大宴会厅C厅成功举办，论坛由浙江省交通运输厅、浙江省交通运输科学研究院联合主办，浙江省工业和信息化研究院、国务院发展研究中心产业经济研究部、交通运输部科技司、招商局交通物流事业部、交通运输部公路科学研究院、浙江省交通集团、浙江省海港集团、浙江省机场集团联合支持。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/培育综合交通产业论坛/嘉宾照片/',
        },
        {
          title: '智慧交通与未来移动出行论坛',
          guests: [
            ['李志胜','浙江省交通运输厅党组成员总工程师','致辞'],
            ['刘文杰','中国公路学会党委副书记、副理事长兼秘书长','主持'],
            ['林 榕','中国交通通信信息中心副主任','智慧交通引领未来交通发展'],
            ['吴德兴','浙江省交通规划设计研究院有限公司董事长、党委书记、总经理','收费公路智慧化建设的决策思考'],
            ['Frank Fickel','IAVGmbH高级项目经理<br>德国ITS-Mobility Nord董事会成员<br>欧盟委员会C-ITS部署平台成员','全球范围内部署合作智能交通系统–智能道路，为智能交通而生'],
            ['李 欢','阿里云交通物流事业部高级架构师','“互联网+交通”的思考'],
            ['崔优凯','浙江省交通规划设计研究院有限公司副总工程师<br>智能交通研究所主任','浙江省智慧高速设计实践'],
            ['刘好德','交通运输部科学研究院城市交通与轨道交通研究中心副主任','后公交都市时代关于MaaS发展的几点思考'],
            ['潘海啸','同济大学城市规划系教授<br>世界交通运输学会常务理事','新交通技术与未来城市'],
            ['邱宗昂','武汉小码联城科技公司运营总监','公交数据共同体及新型公交']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/智慧交通与未来移动出行论坛/论坛视频.mp4',
          desc: '智慧交通与未来移动出行论坛于12月21日下午14:00-18:00在杭州国际博览中心3A-大宴会厅B厅成功举办，论坛由中国公路学会主办，由浙江省交通规划设计院有限公司协办，围绕“出行即服务主题研讨--未来移动出行与公共交通服务系统”做了深入探讨。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/智慧交通与未来移动出行论坛/嘉宾照片/',
        },
        {
          title: '2050我心目中的交通强国论坛',
          guests: [
            ['李好明','交通运输部直属机关党委副巡视员<br>社团党委书记','致辞'],
            ['任 忠','浙江省交通运输厅副厅长','致辞'],
            ['刘永健','长安大学公路学院道路桥梁<br>渡河工程专业建设部主任、教授','专家点评'],
            ['陈 璟','交通运输部规划研究院首席研究员、高级工程师','专家点评']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2050我心目中的交通强国论坛/论坛视频.mp4',
          desc: '2050我心目中的交通强国于12月22日上午9:00-12:00 在杭州国际博览中心3A-大宴会厅C厅成功举办，论坛由共青团交通运输部直属机关委员会、全国高校交通运输类院（系）党委书记论坛组织委员会联合主办，由杭州中交文化发展有限公司、长安大学、哈尔滨工业大学、大连海事大学联合承办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2050我心目中的交通强国论坛/嘉宾照片/',
        },
        {
          title: '长三角区域交通一体化论坛',
          guests: [
            ['缪承潮','杭州市副市长','长三角一体化背景下杭州综合交通高质量发展的实践与思考'],
            ['任 忠','浙江省交通运输厅副厅长','行业智慧化 智慧产业化'],
            ['杨 军','阿里云研究中心战略总监','ET城市大脑助推长三角交通一体化'],
            ['王 进','世界银行交通专家','城市群生态综合交通'],
            ['吴建平','清华大学教授<br>清华大学-剑桥大学-麻省理工学院未来交通研究中心主任','主持、长三角区域的智慧交通发展浅意']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/长三角区域交通一体化论坛/论坛视频.mp4',
          desc: '长三角区域交通一体化论坛于12月21日下午14:30-17:00在杭州国际博览中心3A-大宴会厅D厅成功举办，论坛由浙江省交通运输厅、浙江省经济和信息化厅、杭州市萧山区人民政府联合主办，由萧山经济技术开发区管委会、未来交通研究院、中国智慧交通谷联合支持，做了关于加快提升长三角交通一体化水平的研讨。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/长三角区域交通一体化论坛/嘉宾照片/',
        },
        {
          title: '2022亚运交通科技论坛',
          guests: [
            ['史其信','清华大学土木工程系教授<br>清华大学交通研究所创始人','主持人'],
            ['陈张林','杭州亚运村建设有限公司董事长','杭州亚运村交通规划'],
            ['于春全','2008年北京奥运会北京奥组委交通部部长<br>北京市公安局原副局长','大型活动交通组织与管理——以北京奥运会为例'],
            ['谢振东','广州公共交通集团大数据总监<br>广州智慧公共交通联合创新研究院院长','广州亚运智能交通建设回顾与未来展望'],
            ['刘 琪','中国联通网络技术研究院车联网行业总监（教授级高工）','5G车联网助力智慧奥运'],
            ['贺康静','英国SYSTRA（赛思达）公司高级咨询顾问','奥运对城市交通的影响']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022亚运交通科技论坛/论坛视频.mp4',
          desc: '2022亚运交通科技论坛于12月22日上午9:30-12:00 在杭州国际博览中心3A-大宴会厅成功举办，论坛由浙江省交通运输厅、浙江省经济和信息化厅、杭州市萧山区人民政府联合主办，由萧山经济技术开发区管委会、未来交通研究院、中国智慧交通谷联合支持，议题为“品质交通，保障亚运”。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022亚运交通科技论坛/嘉宾照片/',
        },
        {
          title: '未来交通创新发展论坛',
          guests: [
            
            ['李 萌','清华大学土木水利学院特别研究员、院长助理、博导<br>清华大学交通与地球空间研究所副所长','自动驾驶系统路基智能云控制技术'],
            ['吴建平','清华大学教授<br>清华大学-剑桥大学-麻省理工学院未来交通研究中心主任','自动驾驶与未来城市'],
            ['姚丹亚','清华大学自动化系教授、博士生导师','车路协同系统实践：从测试区走向开放道路'],
            ['谭 昶','科大讯飞股份有限公司智慧城市事业群副总裁','人工智能赋能未来交通'],
            ['金峻臣','银江智慧交通研究院副院长','智慧城市交通控制创新成果及实践'],
            ['朱永盛','阿里巴巴AliOS智慧交通负责人','全方位的车路协同体系-构建未来智能交通'],
            ['吕晓峰','华为技术有限公司 LTE-V2X产品线 总经理','C-V2X车路协同使能智慧交通和自动驾驶'],
            ['傅一平','浙江移动大数据中心总经理','运营商大数据在智慧交通的应用探索'],
            ['温熙华','中电海康集团研究院副院长<br>智慧城市事业部总经理','基于车路协同的新一代智慧交通系统'],
            ['陈喜群','浙江大学智能交通研究所研究员<br>“百人计划”研究员<br>浙江大学建筑工程学院土木工程系副系主任','自动驾照共享出行系统优化与仿真'],
            ['刘兴键','国家技术转移东部中心智能汽车技术转化中心(IVTTC)总工程师兼总经理','未来城市交通变革'],
            ['杨南征','征先管联科技创始人','点单式智能出行新方式-管联网'],
            ['张卫华','教育部长江学者<br>国家杰出青年<br>西南交通大学首席教授','未来轨道交通技术发展'],
            ['吴文赞','北京安道尔轻轨新技术有限公司CEO','PNT与可持续发展的城市规划'],
            ['刘卫国','吉利汽车研究总院资深总工程师','V2X车联网和边缘计算'],
            ['张 雷','杭州远眺科技有限公司副总裁','基于大数据的城市综合交通出行的思考'],
            ['徐千倩','杭州城市大数据运营有限公司副总裁','基于大数据的城市综合交通出行的思考'],
            ['裴世兵','千寻位置网络有限公司业务总监','基于精准时空的出行新体验'],
            ['张宏彬','深圳成谷科技CTO','建设全网自由流，打造智慧高速'],
            ['王瑞利','杭州市公共交通云科技有限公司执行总裁','DT时代的数据引擎-公交数据大脑推动传统公共交通数字化转型升级'],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/未来交通创新发展论坛/论坛视频.mp4',
          desc: '未来交通创新发展论坛于12月22日上午9:00-12:30及下午14:00-17:30在杭州国际博览中心3A-大宴会厅B厅成功举办，论坛由浙江省交通运输厅、浙江省经济和信息化厅、杭州市萧山区人民政府联合主办，由萧山经济技术开发区管委会、未来交通研究院、中国智慧交通谷联合支持，以“创新驱动，未来交通”为议题。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/未来交通创新发展论坛/嘉宾照片/',
        },
        {
          title: '开幕式暨主旨论坛',
          guests: [
            ['高兴夫','浙江省人民政府副省长','致辞'],
            ['翁孟勇','第十三届全国人大常委会委员、中国公路学会理事长','致辞'],
            ['章登峰','杭州市萧山区委副书记，区人民政府区长','致辞'],
            ['陈利幸','浙江省交通运输厅党组书记、厅长','主持人'],
            ['任忠','时任浙江省交通厅党组副书记、副厅长，现任浙江省交通运输厅一级巡视员。','浙江省综合交通产业电子地图'],
            ['吴炜炜','杭州市萧山区人民政府副区长','未来交通小镇规划方案'],
            ['周伟','交通运输部总工程师','为加快建设交通强国努力奋斗'],
            ['马名杰','国务院发展研究中心创新发展研究部部长','发展智慧交通，提升治理能力'],
            ['Neil J. Pedersen','美国交通运输研究委员会（TRB）执行主席','美国交通运输转型技术的现状']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/主旨论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '开幕式暨主旨论坛于2019年12月6日上午9:30-12:00在杭州国际博览中心4F大会议厅成功举办。主旨论坛以“交通新时代 产业大格局”为主题，由浙江省交通运输厅、浙江省经济和信息化厅、杭州市萧山区人民政府联合主办，中国交通运输协会、中国公路学会、交通运输部科学研究院联合支持。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/主旨论坛/嘉宾照片/',
        },
        {
          title: 'COTA全球未来交通发展论坛',
          guests: [
            ['王寅中','浙江省交通运输厅副厅长','致辞'],
            ['吴炜炜','杭州市萧山区人民政府副区长','致辞'],
            ['Neil J. Pedersen','美国交通运输研究委员会（TRB）执行主席','致辞'],
            ['张磊','海外华人交通协会（COTA）主席','主持人'],
            ['Gregory Winfree','美国交通部前副部长、美国德州交通研究院院长','无人驾驶汽车及技术的发展'],
            ['Jonas Eliasson','瑞典皇家工程科学院院士、瑞典交通部运输准入司司长','城市交通政策的效率与公众可接受性'],
            ['罗伯特·贝蒂尼','美国交通部科技司前副司长、美国南佛罗里达大学城市交通研究院院长、教授','关于运输未来的观点：数据和连接性成为推动因素'],
            ['吴德兴','浙江省交通规划设计研究院有限公司董事长','收费公路智慧化建设实践思考'],
            ['张磊','海外华人交通协会（COTA）主席','以人为本：数据和AI驱动下的智能交通系统创新变革	'],
            ['张瑜','美国交通部交通、环境和健康研究中心联合主任、美国南佛罗里达大学副教授','规划未来按需城市空中交通'],
            ['马建明','美国土木工程师协会（ASCE）智能网联车委员会主席、美国德克萨斯交通厅高级工程师','美国德州智能网联交通系统的规划与准备'],
            ['Inas Aweidah','AECOM Technical Services（全球咨询公司）副总裁','新兴技术在不确定世界中的交通规划']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/COTA全球未来交通发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: 'COTA全球未来交通发展论坛以“用世界的语言，讲述交通的故事”为主题，于2019年12月6日下午14:00-18:00在杭州国际博览中心1F会议区-多功能厅A成功举办，由海外华人交通协会（COTA）、未来交通研究院联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/COTA全球未来交通发展论坛/嘉宾照片/',
        },
        {
          title: '一带一路国际交通与物流发展论坛',
          guests: [
            ['洪秀敏','浙江省交通运输厅副厅长','致辞'],
            ['房立群','浙江省商务厅副厅长','致辞'],
            ['Mr.Sabit Subasic','波斯尼亚-黑塞哥维那大使馆代办','致辞'],
            ['Mr.Valeriy Matsel','白俄罗斯驻上海总领事','白俄罗斯——中国实施“一带一路”倡议的重要伙伴'],
            ['Mr.Ramūnas RIMKUS','立陶宛共和国驻华大使馆交通参赞','立陶宛的交通基础设施和物流可能性'],
            ['Mr.Farid Damirli','阿塞拜疆驻华大使馆商务代表','一带一路”倡议:阿塞拜疆对交通物流的愿景'],
            ['Ms.Mária Kovács-Manninger','匈牙利驻上海商务领事','匈牙利-中欧和东欧沿一带一路的物流中心'],
            ['Prof. James Maina','南非工程院院士、南非比勒陀利亚大学教授','智能交通-南非的视角'],
            ['周妍','国际道路运输联盟IRU亚洲及中国首席代表','跨境公路物流在“一带一路”中的发展新机遇'],
            ['Mr. Jan Van der Borght','安特卫普港驻中国代表、上海海事大学客座教授、比利时大使馆经济外交顾问','对“一带一路”中欧贸易的启示'],
            ['Mr.Mateusz Dawidowski','波兰格但斯克港中国区首席代表','共寻共同，共创未来——格但斯克港积极响应一带一路倡议'],
            ['Mr.Hüseyin  CEYHAN','土耳其航空亚太副总裁','铁路到航空:“一带一路”的新路径']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/一带一路国际交通与物流发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '一带一路国际交通与物流发展论坛以“互联互通 开放共赢”为主题，于2019年12月6日14:00-18:00在杭州国际博览中心会议区1F-多功能厅B成功举办，由浙江国际智慧交通产业博览会组委会主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/一带一路国际交通与物流发展论坛/嘉宾照片/',
        },
        {
          title: '智慧高速与自动驾驶论坛',
          guests: [
            ['岑晏青','交通运输部公路科学研究院副院长兼总工程师','主持人'],
            ['任忠','时任浙江省交通厅党组副书记、副厅长，现任浙江省交通运输厅一级巡视员。','浙江智慧公路建设与规划'],
            ['Richard Yeo','澳洲道路交通委员会（ARRB）首席运营官','澳大利亚在网联和自动驾驶车辆、城市智能交通和公路基础 设施数字化方面的创新'],
            ['Ivan Brajdic','威伯科（Wabco） 汽车控制系统亚太区研发总监','商用车从辅助驾驶到自动驾驶的演变'],
            ['曾震宇','阿里云智能副总裁、数据智能总裁','数据智能创造智慧高速未来'],
            ['姚丹亚','清华大学教授','车路协同（V2X）与智慧高速'],
            ['侯德藻','交通运输部公路科学研究院智能交通研究中心主任','新一代智能交通体系框架探讨'],
            ['徐志军','杭州海康威视数字技术股份有限公司副总裁','“AI+数据”赋能高速公路管理与运营'],
            ['秦孔建','中国汽车技术研究中心有限公司智能网联试验研究部部长','智能驾驶系统安全性和适应性测试评估技术'],
            ['陈艳艳','交通运输部公路科学研究院智能交通研究中心副主任','主持人'],
            ['王昌将','浙江省交通规划设计研究院副院长','智慧高速路车协同信息交互技术与应用研究'],
            ['Jan van der Wel','Technolution B.V. CEO','荷兰合作交通管理'],
            ['翟军','北京万集科技股份有限公司董事长兼总经理','基于智能感知与大数据平台的智慧交通'],
            ['冯锐','中兴通讯战略合作总监','中兴车联网战略布局与发展-生态与共赢'],
            ['林在伍','深圳成谷科技有限公司','由自由流到国家交通控制网'],
            ['年劲飞','千寻位置智能驾驶事业部总经理','时空智能：智慧交通基础设施'],
            ['任世岩','大唐电信科技产业集团车联网产品线总监','C-V2X未来发展之路']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/智慧高速与自动驾驶论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '智慧高速与自动驾驶论坛于2019年12月7日09:00-17:00在杭州国际博览中心1F－会议区103，由交通运输部公路科学研究院主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/智慧高速与自动驾驶论坛/嘉宾照片/',
        },
        {
          title: '第二届未来交通创新发展论坛',
          guests: [
            ['李志胜','浙江省交通运输厅总工程师','致辞'],
            ['史其信','清华大学土木工程系教授、清华大学交通研究所创始人','致辞'],
            ['赵鸿鸣','浙江浙大中控信息技术有限公司总裁','绿色、安全、智慧的未来城市交通系统'],
            ['温熙华','中电海康集团研究院副院长、浙江海康智联科技有限公司总经理','携手共筑“聪明道路”'],
            ['许亮','商汤科技执行总监','商汤科技智能汽车解决方案'],
            ['孙勇义','百度自动驾驶事业部总经理','开放的自动驾驶与智能交通'],
            ['熊卓越','湖州莫干山高新区党工委委员、管委会副主任','德清自动驾驶与智慧出行示范区规划与建设工作'],
            ['陈希','上海市城市建设设计研究总院（集团）有限公司智慧城市所所长','新形势下研究院如何发展智能交通专业'],
            ['张峰','爱上集团技术部总经理','自动驾驶的应用场景实践'],
            ['陈喜群','浙江大学智能交通研究所研究员、“百人计划”研究员、博士生导师、浙江大学建筑工程学院土木工程学系副系主任','共享出行交通管理'],
            ['徐甲','银江智慧交通研究院副院长','下一代城市交通控制之路'],
            ['李启宗','北京博研智通科技有限公司副总经理','开放式精细化智能交通信号控制系统']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/第二届未来交通创新发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '第二届未来交通创新发展论坛以“交通引领未来”为主题，于2019年12月7日14:00-17:30在杭州国际博览中心会议区1F-多功能厅B成功举办，由未来交通研究院主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/第二届未来交通创新发展论坛/嘉宾照片/',
        },
        {
          title: '未来城市智慧交通发展论坛',
          guests: [
            ['蔡洪','浙江省交通运输厅副厅长','致辞'],
            ['金盛','浙江大学副教授','主持人'],
            ['李瑞敏','清华大学土木工程系副教授、博士生导师','自动驾驶时代的交通设施规划与设计思考'],
            ['刘干','南京赛康交通安全科技股份有限公司董事长','国家级重大项目智能网联路侧设施创新应用'],
            ['高翔','上海市城市建设设计研究总院（集团）有限公司智能交通所所长','智能网联环境下的智能交通系统建设思考'],
            ['戴显荣','浙江省交通规划设计研究院有限公司副总经理，教授级高工','浙江省未来社区交通解决方案'],
            ['梁坚','浙江创泰科技有限公司董事长','5G赋能智慧停车未来'],
            ['王鹏','华为技术有限公司智慧交通解决方案CTO','云智融合，共赴智慧交管新时代'],
            ['孙伟力','滴滴智慧交通方案总工程师','移动出行数据在城市交通管理中的应用探索'],
            ['金仲明','阿里云智能达摩院机器智能技术高级算法专家','图神经网络在交通预测应用']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/未来城市智慧交通发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '未来城市智慧交通发展论坛于2019年12月7日下午14:00-17:00在杭州国际博览中心1F-会议区102成功举办，由赛文交通网、未来交通研究院联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/未来城市智慧交通发展论坛/嘉宾照片/',
        },
        {
          title: '浙江综合交通产业发展论坛暨招商推介会',
          guests: [
            ['董贵波','浙江省人民政府副秘书长','现代综合交通产业发展的实践与思考'],
            ['任忠','时任浙江省交通厅党组副书记、副厅长，现任浙江省交通运输厅一级巡视员。','主持人'],
            ['缪承潮','杭州市人民政府副市长','现代综合交通产业发展的实践与思考'],
            ['沈敏','宁波市人民政府副市长','做优做强航运服务  助推建设“一带一路”'],
            ['陈建明','温州市委常委、市人民政府常务副市长','聚力打造全国性综合交通枢纽 奋力推动综合交通产业大发展'],
            ['项乐民','湖州市人民政府副市长','绿水青山就是金山银山 “两山”理论在湖州绿色交通的实践'],
            ['洪湖鹏','嘉兴市委常委、嘉善县委书记','发挥长三角一体化和接轨上海桥头堡优势 带动综合交通产业发展'],
            ['栾国栋','绍兴市委常委、市人民政府副市长','聚焦杭绍甬一体化 推进综合交通产业迈上新台阶'],
            ['陈晓','原金华市委常委、市人民政府常务副市长','开放引领建设贸易大通道  创新驱动打造全球自由港——金华打造金义国际陆港新区的实践与设想'],
            ['王良春','衢州市人民政府副市长','超前谋划 示范先行 接中小城市新型轨道交通发展期到来'],
            ['毛江平','舟山市人民政府副市长','对标国际一流高质量发展国际海事服务产业'],
            ['林先华','台州市人民政府副市长','台州市门头港打造港、产、城、湾融合发展的国家级湾区经济技术开发区'],
            ['楼志坚','丽水市人民政府副市长','大花园核心区背景下的丽水交通实践'],
            ['康理','东风汽车集团有限公司技术中心党委书记','东风Sharing-X移动服务重构城镇建设'],
            ['呙润华','清华大学土木工程系副教授、清华大学交通研究所副所长','充分发挥交通基础设施引领作用的“现代交通+”行业战略思考']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/浙江综合交通产业发展论坛暨招商推介会/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '浙江综合交通产业发展论坛暨招商推介会以“交通赋能 产业创生”为主题，于2019年12月7日14:30-19:30在杭州国际博览中心会议区1楼论坛区一、成果发布区成功举办，由浙江省交通运输厅主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/浙江综合交通产业发展论坛暨招商推介会/嘉宾照片/',
        },
        {
          title: '综合交通产业创新大赛总决赛暨同期配套活动颁奖仪式',
          guests: [
            ['任忠','时任浙江省交通厅党组副书记、副厅长，现任浙江省交通运输厅一级巡视员。','致辞']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/综合交通产业创新大赛总决赛暨同期配套活动颁奖仪式/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '综合交通产业创新大赛总决赛暨同期配套活动颁奖仪式以“智慧交通 驱动未来”为主题，于2019年12月8日9:00-12:00在杭州国际博览中心会议区1F-多功能厅B成功举办，由浙江国际智慧交通产业博览会组委会主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/综合交通产业创新大赛总决赛暨同期配套活动颁奖仪式/嘉宾照片/',
        },
        {
          title: '通用航空产业发展论坛',
          guests: [
            ['郑向平','浙江省机场集团有限公司总经理','致辞'],
            ['马松伟','中国民航出版社社长','致辞'],
            ['杨福颂','杭州市投资促进局副局长','致辞'],
            ['廉秀琴','中国航空局民用航空司副司长','致辞'],
            ['谢长庆','中国民航报社副社长','主持人'],
            ['张锐','中国民用航空局机场司副司长','持续深化通用机场管理改革 推进“两翼齐飞”'],
            ['蔡洪','浙江省交通运输厅副厅长','抢抓机遇 乘风启航 推动浙江通用航空高质量发展'],
            ['秦刚','民航浙江安全监督管理局局长','携手并进 抢抓机遇 加快实现我省通航高质量发展'],
            ['徐树雄','浙江省机场集团有限公司副总经理','精准定位  定向发力 全力推动全省通航产业发展'],
            ['苏龙龙','中国航空器材集团总经理助理','打造浙江省新时代智慧航空救援体系'],
            ['吴瑞华','建德经济开发区（航空小镇）管委会副主任','依托航空小镇建设，高质量打造通航产业 发展“浙江样板”'],
            ['丁跃','中国通用航空协会筹备组负责人','智慧通航--助力通航产业高质量发展'],
            ['刘祥仁','美国塞斯纳在华合资企业董事长','创新通航发展模式 探索中国理性公务机商业平台'],
            ['高远洋','北京航空航天大学通用航空产业中心主任','营造区域运营环境 促进浙江通用航空产业发展'],
            ['刘光才','中国民航大学通航学院副院长','聚焦教育培训，促进通航发展'],
            ['潘国强','浙江交通职业技术学院航空学院院长','践行当代民航精神，打造机修人才培养基地'],
            ['石开仑','华东通用航空中心总经理助理','华东通用航空服务中心试点工作介绍']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/通用航空产业发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '通用航空产业发展论坛以“创新通航发展模式 助力民航强省建设”为主题，于2019年12月8日上午9:00-12:00在杭州国际博览中心1F会议区-多功能厅A，由浙江省机场集团有限公司、中国民航报社、杭州市投资促进局联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/通用航空产业发展论坛/嘉宾照片/',
        },
        {
          title: 'AI+智慧交通产业发展论坛',
          guests: [
            ['孙晓明','浙江省交通运输厅副厅长','致辞'],
            ['于泉','北京工业大学城市交通学院副教授','主持人'],
            // ['于泉','北京工业大学城市交通学院副教授','交通大脑新探索'],
            ['苏岳龙','高德地图未来交通与城市计算联合实验室主任','智融、智联、智控、惠行'],
            ['吴柯维','北京卓视智通科技有限责任公司总经理','AI交通视频分析与数字孪生应用'],
            ['沈国江','浙江工业大学计算机智能系统研究所所长','大数据时代城市广域交通管控一体化技术'],
            ['金峻臣','银江智慧交通研究院副院长','城市交通拥堵传播规律与AI治理策略'],
            ['张雷','杭州远眺科技有限公司副总裁','基于大数据的综合交通指挥中心的现在与未来'],
            ['施南征','杭州海康威视数字技术股份有限公司行业市场总监','系统思维，科技赋能，助力交通管理精细化']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/AI%2B%E6%99%BA%E6%85%A7%E4%BA%A4%E9%80%9A%E4%BA%A7%E4%B8%9A%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: 'AI+智慧交通产业发展论坛于2019年12月8日上午09:00-12:00在杭州国际博览中心1F-会议区102成功举办，由赛文交通网、未来交通研究院联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/AI%2B%E6%99%BA%E6%85%A7%E4%BA%A4%E9%80%9A%E4%BA%A7%E4%B8%9A%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B/嘉宾照片/',
        },

        // 两级大会
        {
          title: '开幕式暨主旨论坛',
          guests: [
            ['高兴夫','浙江省人民政府副省长','致辞'],
            ['周伟','交通运输部总工程师','致辞'],
            ['汤志平','上海市人民政府副市长','致辞'],
            ['刘捍东','江苏省人大常委会副主任','致辞'],
            ['何树山','安徽省人民政府副省长','致辞'],
            ['董贵波','浙江省人民政府副秘书长','主持人'],
            ['翁孟勇','第十三届全国人大常委会委员、中国公路学会理事长','致辞'],
            ['陈湘生','中国工程院院士、深圳大学土木与交通工程学院院长','轨道交通与都市圈的协同发展'],
            ['沙爱民','长安大学党委副书记、校长','长三角一体化背景下绿色智慧公路发展'],
            ['胡华清','中国民航科研院民航发展研究所常务副所长','长三角机场群协同发展思考']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/开幕式暨主旨论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '第二届长三角综合交通发展大会以“聚力融通，先行引领”为主题，于2019年12月6日14:30-16:30在杭州国际博览中心会议区2F-新闻发布厅成功举办，由浙江省交通运输厅、浙江省发展和改革委员会联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/开幕式暨主旨论坛/嘉宾照片/',
        },
        {
          title: '长三角综合交通协同发展论坛',
          guests: [
            ['詹小张','浙江省交通投资集团有限公司总经理','主持人'],
            ['陈利幸','浙江省交通运输厅党组书记、厅长','主题演讲'],
            ['谢峰','上海市交通委员会党组书记、主任','服务国家战略 促进区域发展 不断完善上海现代综合交通运输体系'],
            ['陆永泉','江苏省交通运输厅党组书记、厅长','为长三角区域一体化发展贡献江苏交通力量'],
            ['章义','安徽省交通运输厅党组书记、厅长','扬皖所长，以交通一体化助力长三角区域协同发展'],
            ['徐国毅','上海组合港管委会办公室主任','高质量发展长三角一体化，高水准推动港航资源集约化'],
            ['周华富','浙江省发展规划研究院院长','港航协同打造世界级长三角港口群'],
            ['李玉涛','国家发展和改革委员会综合运输研究所副主任','长三角一体化视角下的综合运输体系重构'],
            ['张瑜','原美国华人交通协会（COTA）主席、美国南佛罗里达大学副教授','机场群的发展趋势及其在区域经济中的重要作用'],
            ['沈坚','浙江省交通规划设计研究院总经理','“四港”联动发展——对长三角综合交通一体化的启示']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角综合交通协同发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '长三角综合交通协同发展论坛以“高质量、一体化，协同发展”，于2019年12月7日上午9:00-12:00在杭州国际博览中心1F-会议区多功能厅A成功举办，由浙江省交通运输厅、浙江省发展和改革委员会联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角综合交通协同发展论坛/嘉宾照片/',
        },
        {
          title: '长三角未来交通发展论坛',
          guests: [
            ['李志胜', '浙江省交通运输厅党组成员、总工程师','致辞'],
            ['韩海航','浙江省交通运输科学研究院副院长','主持人'],
            ['李兴华','同济大学中国未来交通研究院院长','实施《交通强国建设纲要》描绘未来交通图景'],
            ['薛美根','上海市城乡建设和交通发展研究院副院长','关于长三角一体化示范区综合交通体系构建的若干思考'],
            ['岑晏青','交通运输部公路科学研究院副院长兼总工程师','以交通强国为目标的智慧交通建设思考'],
            ['张越','中国民航科研院民航发展规划研究院总经济师','长三角一体化背景下浙江省民用机场体系研究'],
            ['施践','上海组合港管委会办公室信息处处长','长三角智能航运应用与发展'],
            ['刘晓捷','蚂蚁金服大出行服务部总经理','绿色出行 智慧交通——基于长三角一体化的出行服务创新'],
            ['张辉','长三角“5G+智能驾驶”协同创新联盟秘书长','打造长三角车路云一体化智能网联交通系统'],
            ['施伟斌','英达公路再生科技集团董事长','自主创新“三元论”释义-绿色养护应用启示']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角未来交通发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '长三角未来交通发展论坛以“区域共享、互联互通，交融天下、引领未来”为主题，于2019年12月7日上午09:00—12:00在杭州国际博览中心1F-会议区102成功举办，由浙江省交通运输厅、浙江省发展和改革委员会联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角未来交通发展论坛/嘉宾照片/',
        },
        {
          title: '长三角轨道交通发展论坛',
          guests: [
            ['马德富','浙江省发改委二级巡视员','致辞'],
            ['陈江','浙江省交通投资集团有限公司副总经理','主持人'],
            ['汪鸣','国家发展和改革委员会综合运输研究所所长','长三角轨道交通发展思路'],
            ['郭继孚','北京交通发展研究院院长、教授级高工','谈建设轨道上的城市群'],
            ['彼得.慕尼希','中国国家千人高端专家、德国轨道工业协会中国全权首席代表','未来轨交新制式发展解析'],
            ['刘建红','中铁上海设计院集团有限公司院长','长三角综合交通中铁路对客货运作用的思考'],
            ['何志工','中铁第四勘察设计院集团有限公司副总工程师、教授级高工','轨道交通四网融合与一体化建设'],
            ['李克武','华为技术有限公司华为企业中国区CTO','打造轨道交通产业发展新高地-交通信息化技术创新与发展']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角轨道交通发展论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '长三角轨道交通发展论坛以“轨道交通让生活更美好”为主题，于2019年12月7日09:00-11:00在杭州国际博览中心会议区1F-多功能厅B成功举办，由浙江省交通运输厅、浙江省发展和改革委员会联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角轨道交通发展论坛/嘉宾照片/',
        },
        {
          title: '开幕式暨主旨论坛',
          guests: [
            ['高兴夫','浙江省人民政府副省长','致辞'],
            ['陈山枝','中国信息通信科技集团有限公司副总经理、专家委主任，无线移动通信国家重点实验室主任，IEEE Fellow','致辞'],
            ['张延川','中国通信学会副理事长兼秘书长，教授级高工','主持人'],
            ['邬贺铨','中国工程院院士','5Ｇ车联网的挑战'],
            ['王笑京','交通运输部公路科学研究院研究员，国家智能交通系统工程技术研究中心项目首席科学家，世界智能交通大会理事会理事和亚太智能交通协会理事','关注新一代智能交通 • 探路车联网应用落地'],
            ['郭继孚','北京交通发展研究院院长，教授级高工','大规模网联车辆协同服务'],
            // ['陈山枝','中国信息通信科技集团有限公司副总经理、专家委主任，无线移动通信国家重点实验室主任，IEEE Fellow','5G及C-V2X车联网的进展与展望']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/开幕式暨主旨论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '2019中国车联网技术与应用发展大会开幕式暨主旨论坛以“车联网·未来智慧交通愿景”为主题，于2019年12月7日09:00-12:00在杭州国际博览中心会议区2F-新闻发布厅成功举办，由中国通信学会、杭州市萧山区人民政府联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/开幕式暨主旨论坛/嘉宾照片/',
        },
        {
          title: '5G赋能交通发展论坛',
          guests: [
            ['袁毅','浙江省信息通信协会副理事长兼常务副秘书长','主持人'],
            ['徐德平','中国移动通信集团设计院上海分院副院长','新技术催生新应用 新产业共谋新未来'],
            ['李文','大唐移动5G产品线副总裁、移动通信事业部副总经理','5G车联网业务发展'],
            ['熊小敏','中国电信北京研究院业务与应用创新所产品总监，车联网专业负责人','基于MEC的车路协同应用探索'],
            ['白柯柯','中兴通讯移动互联产品总经理','5G助力智慧交通'],
            ['万森林','浙江大华技术股份有限公司智慧交通解决方案部副总监','智慧交通解决方案的落地探讨'],
            ['李静林','北京邮电大学网络与交换技术国家重点实验室交换与智能控制研究中心主任','交通智联网与智能驾驶服务'],
            ['刘乾','开沃汽车智行板块（南京酷沃智行科技有限公司）总经理','AIOT场景下的车家智能一体化趋势'],
            ['胡波','上海复亚智能科技有限公司市场总监','5G无人机全自动飞行系统'],
            ['董宇','北京合众利讯管理咨询有限公司 合伙人及高级顾问','智能网联汽车演进中的机遇与挑战']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/5G赋能论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '5G赋能交通发展论坛于12月7日 14:00-17:00 在杭州国际博览中心会议区2楼新闻发布厅CD成功举办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/5G赋能论坛/嘉宾照片/',
        },
        {
          title: '车联网技术与应用发展论坛',
          guests: [
            ['胡延明','中国通信学会通信设备制造技术委员会副主任委员','主持人'],
            ['葛雨明','中国信息通信研究院C-V2X工作组组长','C-V2X技术与产业化进程'],
            ['郭磐石','四维图新车路协同研究院副院长','智能地图赋能车路协同，打造新一代智慧公路'],
            ['毕海洲','大唐高鸿车联网事业部副总经理','C-V2X标准进展和产业实践'],
            ['王健','吉林大学计算机学院教授、博士生导师','C-V2X测试和融合测试方案'],
            ['沈涛','海康威视交通方案部高级总监','车路协同下交通管理服务新探索'],
            ['陈卫强','厦门金龙联合汽车工业有限公司CTO','金龙智能网联研发及产业化情况介绍'],
            ['贾广宏','北京汽车集团产业投资有限公司 董事总经理','下一站繁荣——车联网领域投资机遇分析'],
            ['潘坚伟','AutoX中国区负责人兼技术总监','打造国际领先的自动驾驶出行和物流解决方案'],
            ['刘鹏','北京仁信证科技有限公司CTO','C-V2X安全分析与实践'],
            ['石勇','星云互联联合创始人兼首席运营官','车路协同助力智慧驾驶应用']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/技术与应用论坛/论坛视频.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg',
          desc: '车联网技术与应用发展论坛于2019年12月7日14:00-17:00在杭州国际博览中心会议区2F新闻发布厅AB成功举办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/技术与应用论坛/嘉宾照片/',
        },

        // 第三届
        {
          title: '开幕式暨主旨论坛',
          guests: [
            ['陈利幸','浙江省交通运输厅党组书记、厅长','主持人'],
            ['刘小涛','时任浙江省人民政府副省长、党组成员，现任浙江省委常委、温州市委书记。','致辞'],
            ['卢春房','中国工程院院士、中国铁道学会理事长','高速铁路的优势与作用'],
            ['王坚','中国工程院院士、 阿里云创始人、阿里巴巴集团技术委员会主席','城市数字化与城市大脑'],
            ['赵文波','国防科工局重大专项工程中心副主任、国际宇航学院院士','中国遥感卫星发展及在智慧交通中的应用'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/916122354416981-001%E5%BC%80%E5%B9%95%E5%BC%8F%E4%B8%BB%E6%97%A8%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/116046472167677-%E5%BC%80%E5%B9%95%E5%BC%8F%E6%9A%A8%E4%B8%BB%E6%97%A8%E8%AE%BA%E5%9D%9B.jpg',
          desc: '开幕式暨主旨论坛于12月4日上午10：00—12：30在杭州国际博览中心4F大会议厅成功举办。以“交通强国新征程  产业发展新动能”为主题，由浙江省交通运输厅、浙江省发展和改革委员会、浙江省经济和信息化厅、杭州市萧山区人民政府联合主办。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '新基建和智慧高速论坛',
          guests: [
            ['岑晏青','交通运输部公路科学研究院副院长','智慧公路技术体系构建及应用发展态势'],
            ['任忠','时任浙江省交通厅党组副书记、副厅长，现任浙江省交通运输厅一级巡视员。','浙江智慧高速建设探索与实践'],
            ['杨国峰','招商局公路网络科技控股股份公司高级总监','招商公路对智慧高速的思考与实践'],
            ['吕晓峰','华为有限公司无线战略业务部副总裁','车路云协同助力商用车自动驾驶率先落地'],
            ['毛向阳','上汽集团商用车技术中心副总监','洋山港智能重卡开发与实践'],
            ['刘峰','雷科防务科技股份有限公司总经理','车路协同建设中的几点思考'],
            ['张宏彬','深圳成谷科技有限公司CTO','智慧高速新基建的主要问题及探讨'],
            ['张辉','新奇点智能科技集团董事长','数字交通新基建：车路协同，人机共驾'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/916122350539843-004%E6%96%B0%E5%9F%BA%E5%BB%BA%E5%92%8C%E6%99%BA%E6%85%A7%E9%AB%98%E9%80%9F.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/716051987444600-%E6%96%B0%E5%9F%BA%E5%BB%BA%E5%92%8C%E6%99%BA%E6%85%A7%E9%AB%98%E9%80%9F%E8%AE%BA%E5%9D%9B.jpg',
          desc: '本次会议邀请行业内重要专家，共同探讨在新基建条件下，如何推进智慧高速公路建设，加快产业落地，实现交通出行安全、高效、便捷、绿色、经济的愿景。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '强港论坛·中国港口高质量发展报告（2020）发布会',
          guests: [
            ['胡旭铭','时任浙江省交通运输厅党组成员、省港航管理中心主任，现任浙江省交通运输厅党组成员，浙江省港航管理局局长、党委书记，浙江省海洋港口发展委员会(筹)副主任。','主持人'],
            ['陈英明','中国港口协会常务副会长','致辞'],
            ['贾大山','交通运输部水运科学研究院副院长','中国港口高质量发展报告（2020）解读'],
            ['金雷','中国经济信息社经济智库事业部副总经理','中国港口高质量发展报告（2020）解读'],
            ['徐国毅','上海组合港管委会办公室主任','新发展格局下的长三角世界级港口群建设'],
            ['陶成波','浙江省海港集团、宁波舟山港集团总经理','服务国家战略，强化“硬核”担当'],
            ['真虹','上海国际航运研究中心秘书长','世界一流强港建设中值得关注的几个趋势性问题'],
            ['李飞','阿里云高级解决方案架构师','云计算助力数字化强港建设'],
            ['赵楠','上海国际航运研究中心副秘书长','全球港口发展报告（2019）分享'],
            ['施敏夫','马士基集团副总裁，马士基码头公司亚太总裁','“后疫情”时代的全球港航业发展'],
            ['圣地亚哥·加西亚·米拉','国际港湾协会（IAPH）主席，巴塞罗那港务局副局长','全球港口发展新趋势'],
            ['谭伟华','新加坡海事与港务管理局工程与项目管理高级署长兼总工程师、首席可持续发展官','新加坡新一代港口，充满弹性的未来之港'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/516122472140165-005%E5%BC%BA%E6%B8%AF%E8%AE%BA%E5%9D%9B%C2%B7%E4%B8%AD%E5%9B%BD%E6%B8%AF%E5%8F%A3%E9%AB%98%E8%B4%A8%E9%87%8F%E5%8F%91%E5%B1%95%E6%8A%A5%E5%91%8A%EF%BC%882020%EF%BC%89%E5%8F%91%E5%B8%83%E4%BC%9A.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/916054927570691-%E5%BC%BA%E6%B8%AF%E8%AE%BA%E5%9D%9B%C2%B7%E4%B8%AD%E5%9B%BD%E6%B8%AF%E5%8F%A3%E9%AB%98%E8%B4%A8%E9%87%8F%20%E5%8F%91%E5%B1%95%E6%8C%87%E6%95%B0%EF%BC%882020%EF%BC%89%E5%8F%91%E5%B8%83%E4%BC%9A.jpg',
          desc: '党的十九大提出打造“陆海内外联动、东西双向互济的开放新格局”，中央政治局会议提出“加快形成以国内大循环为主体、国内国际双循环相互促进的新发展格局”，习近平总书记在宁波舟山港考察时指出，宁波舟山港是服务国家战略的“硬核”力量，要努力打造“世界一流强港”。基于以上背景，论坛主题拟为“联双通道，促双循环：世界一流强港的‘硬核’担当”。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;论坛着眼于“十四五”及未来一段时间中国港口的建设发展，围绕建设“一流设施、一流技术、一流管理、一流服务”港口的目标，就联结陆海双通道、促进内外双循环，打造“世界一流强港”、强化“硬核”使命担当等进行研讨。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '智能轨道发展论坛',
          guests: [
            ['陈江','浙江省交通投资集团有限公司董事、副总经理、党委委员','主持人'],
            ['李军','浙江省发改委副主任','致辞'],
            ['姚世峰','中国城市轨道交通协会信息化专业委员会秘书长','从信息化走向数字化——关于城轨道企业信息化发展趋势的探讨'],
            ['刘建红','中铁上海设计院集团有限公司院长','轨道交通四网融合的技术探讨'],
            ['杨立新','中国铁路设计集团有限公司副总工程师','BIM技术助力智能精品工程建设'],
            ['张建明','国家信息中心质检中心副主任','智慧轨道与关键信息基础设施安全融合发展'],
            ['李平','中国铁道科学研究院集团有限公司首席研究员、博士生导师','中国智能铁路体系架构与创新实践'],
            ['娄永梅','中国铁路通信信号股份有限公司首席专家','城轨智能运维的发展和探索'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/416122476810417-006%E6%99%BA%E8%83%BD%E8%BD%A8%E9%81%93%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/816069097312599-%E6%99%BA%E8%83%BD%E8%BD%A8%E9%81%93%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.jpg',
          desc: '为认真贯彻习近平总书记关于新基建系列重要指示精神和考察浙江重要讲话精神，扛起“重要窗口”的使命担当，浙江将以交通强国建设纲要为统领，构建安全、便捷、高效、绿色、经济的现代化综合交通体系，打造“一流设施、一流技术、一流管理、一流服务”的浙江省特色交通管理模式。本论坛以新兴信息技术与轨道交通深度融合为主线，着眼于轨道交通数字化与智能化，聚焦于打造“浙省轨道一张网”统管格局，研究探讨轨道交通未来发展趋势、智能轨道应用、四网融合、信息安全等内容。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '长三角地下空间综合发展高峰论坛',
          guests: [
            ['钟方杰','水下隧道智能设计、建造与养护技术与装备交通运输行业研发中心常务副主任','主持人'],
            ['洪秀敏','浙江省交通运输厅副厅长','致辞'],
            ['邵文年','浙江省交通投资集团有限公司副总经理','致辞'],
            ['蒋树屏','山区桥梁与隧道工程国家重点实验室隧道方向首席科学家、全国工程勘察设计大师','城市地下道路互通式立交技术'],
            ['刘艺','上海市政工程设计研究总院（集团）有限公司总院副总工程师','基于长三角一体化背景下的地下空间开发利用思考'],
            ['江勇','浙江省城乡规划设计研究院有限公司','浙江省城市地下空间开发利用特征、模式与实践探索研究'],
            ['黄俊','苏交科集团股份有限公司地下空间院院长、江苏省地下空间学会常务副会长','江苏省地下空间发展实践与思考'],
            ['俞建霖','浙江大学滨海和城市岩土工程研究中心、浙江省城市地下空间开发工程技术研究中心副主任','基坑工程地下水控制'],
            ['吴德兴','浙江省交通规划设计研究院有限公司董事长、全国工程勘察设计大师','大型地下道路空间集约化利用'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/116122479429648-007%E9%95%BF%E4%B8%89%E8%A7%92%E5%9C%B0%E4%B8%8B%E7%A9%BA%E9%97%B4%E7%BB%BC%E5%90%88%E5%8F%91%E5%B1%95%E9%AB%98%E5%B3%B0%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/916051987619862-%E9%95%BF%E4%B8%89%E8%A7%92%E5%9C%B0%E4%B8%8B%E7%A9%BA%E9%97%B4%E7%BB%BC%E5%90%88%E5%8F%91%E5%B1%95%E9%AB%98%E5%B3%B0%E8%AE%BA%E5%9D%9B.jpg',
          desc: '长三角地下空间综合发展高峰论坛为第三届浙江国际智慧交通产业博览会下的主题论坛之一。论坛将聚焦长三角地区地下空间建设发展实践，邀请国内外领域内顶尖专家以及长三角三省一市地下空间规划、建设一线权威学者，共同畅谈未来交通地下空间综合利用发展热点和前沿理论，为推动长三角一体化高质量发展凝聚共识、汇集智慧。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '“四港”联动发展论坛',
          guests: [
            ['毛剑宏','浙江“四港”联盟理事长、浙江海港集团董事长','主持人'],
            ['蔡洪','浙江省交通运输厅副厅长','致辞'],
            ['王立忠','浙江大学党委常委、副校长','加快构建 “四港”联动创新发展体系'],
            ['赵丽建','中国铁路上海局集团副总经理','强化陆港合作,推动长三角多式联运高质量发展'],
            ['滕亚辉','浙江“四港”联盟 秘书长','智慧物流云平台与“四港”联动发展'],
            ['郑向平','浙江省机场集团总经理','打造浙江国际航空货运优质生态圈 助力“四港”联盟发展'],
            ['陈坚','传化智联执行总裁','传化智慧公路网络赋能“四港”联动'],
            ['葛程捷','上海圆通蛟龙集团副总裁','物流信息数据共享的研究与设想'],
            ['关威','Tradelens 亚太区负责人','联通陆海，区块链助力数字化合作'],
            ['高学亨','Flexport 亚太区董事总经理','新常态下的转型与升级：打造全球贸易数字化平台的实践'],
            ['金国强','浙江省交通规划设计研究院副总工、水运院院长','“四港联动”背景下的海河联运运输体系建设'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/316122494500934-008%E2%80%9C%E5%9B%9B%E6%B8%AF%E2%80%9D%E8%81%94%E5%8A%A8%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/216067225690113-%E5%9B%9B%E6%B8%AF%E8%81%94%E5%8A%A8%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.jpg',
          desc: '论坛将围绕全省开放大会提出的“着力推进海港、陆港、空港、信息港联动发展，加快实现海上、路上、空中、网上联通”，即“四港”联动发展的要求，邀请行业权威和资深从业者到会分享，对我省加快推进相关工作中有关体系建设、平台搭建、主体合作、市场实践等多个议题进行研讨，力求多视角展现“四港”联动发展的现状和未来，探索打造以多式联运、无缝对接、快捷便利、成本低廉为特征的“四港”联动发展的现代化综合交通运输体系。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: 'F5G助力智慧交通发展论坛',
          guests: [
            ['吴红梅','浙江省发展规划研究院副院长','致辞'],
            ['李志胜','浙江省交通运输厅厅党组成员、总工程师','致辞'],
            ['郭祥寿','中铁二院地铁院副总工程师','新时代轨道交通光通信网发展趋势以及未来的展望'],
            ['金志国','华为企业传送与接入领域总裁','F5G智慧交通助力交通数字化转型'],
            ['徐寅翔','杭州地铁通信主管','浙江地铁数字化通信建设经验分享'],
            ['冯华超','中国铁路设计集团有限公司广东分公司机电总体负责人','打造智慧枢纽，建设数字化全光车站'],
            ['周良兵','贵州高速数据运营有限公司技术部部长','高速公路数字化通信建设分享'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/916122499917413-009F5G%E5%8A%A9%E5%8A%9B%E6%9C%AA%E6%9D%A5%E4%BA%A4%E9%80%9A%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/016061188108501-F5G%E5%8A%A9%E5%8A%9B%E6%99%BA%E6%85%A7%E8%BD%A8%E9%81%93%E4%BA%A4%E9%80%9A%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.jpg',
          desc: '在交通强国战略和产业变革的浪潮推动下，交通运输行业发展已步入数字化转型的关键时期，依托5G、F5G、云、人工智能、大数据、物联网、机器视觉等数字技术打造交通“新基建”时代下的智慧大交通，已经成为交通强国建设进程种的普遍共识。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;华为坚持“平台+生态”的战略，携手行业合作伙伴，致力于构建“数字化感知”、“泛在联接”、“数字平台”、“智慧应用”的体系，加速实现交通基础设施的数字化，围绕城市航空、城轨、公路、铁路、水运等方面的建设、管理、运营一体化、强化数字技术的持续深度应用，实现综合大交通的安全、效率、体验等全面升级。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '第三届未来交通创新发展论坛',
          guests: [
            ['李萌','清华大学土木水利学院院长助理、研究员、博导','主持人'],
            ['孙晓明','浙江省交通运输厅党组成员（副厅长级）','致辞'],
            ['吴炜炜','杭州市萧山区区委常委、常务副区长','致辞'],
            ['史其信','中国智慧交通发起人、清华大学交通研究所原所长、教授','致辞'],
            ['张毅','清华大学系统工程研究所所长、教授','新基建驱动下的车路协同应用发展'],
            ['张姿','杭州海康威视数字技术股份有限公司交通行业总经理','“新基建，新契机”--海康交通数字化，智能化实践探索'],
            ['关志超','交通运输部智能车路协同关键技术及装备行业研发中心执行主任、深圳市综合交通运行指挥中心总工程师','“新基建+综合交通大脑”数字化转型的合力与动能'],
            ['杨彦鼎','东风汽车集团有限公司技术中心副主任','中国汽车产城融合创新发展之路'],
            ['李萌','大唐移动战略市场部市场总监','“5G+车路协同”——打造智慧交通新体系'],
            ['刘云鹏','浙江海康智联科技有限公司总助、技术负责人、高工','车路协同，打造新基建时期创新发展新引擎'],
            ['辛龙超','新华三集团交通行业技术总监','面向车路协同的新一代智慧高速信息化建设'],
            ['蒋萍','龙兴航电首席数字官','未来智慧交通之飞行车展望'],
            ['张磊','阿里云城市大脑首席科学家','以人为本的技术创新推动车路协同商业化发展'],
            ['陈喜群','浙江大学建筑工程学院土木工程学系副系主任、“百人 计划”研究员、博士生导师','基于AI的路网交通运行状态估计与预测'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/116122515882881-010%E7%AC%AC%E4%B8%89%E5%B1%8A%E6%9C%AA%E6%9D%A5%E4%BA%A4%E9%80%9A%E5%88%9B%E6%96%B0%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/016051987832472-%E7%AC%AC%E4%B8%89%E5%B1%8A%E6%9C%AA%E6%9D%A5%E4%BA%A4%E9%80%9A%E5%88%9B%E6%96%B0%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.jpg',
          desc: '论坛围绕当下交通行业热点、焦点话题和交通领域新技术、新应用进行展开，为交通行业的发展和方向提供智力支持，本届论坛还将主要围绕新基建背景下车路协同技术成果和应用落地进行探讨。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '第二届综合交通创新创业大赛总决赛暨颁奖仪式',
          guests: [
            ['王寅中','浙江省交通运输厅副厅长','致辞'],
            ['张毅','中交一公路勘察设计研究院有限公司副总经理','致辞'],
          ],
          video: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/attachments/916122530618794-011%E7%AC%AC%E4%BA%8C%E5%B1%8A%E7%BB%BC%E5%90%88%E4%BA%A4%E9%80%9A%E5%88%9B%E6%96%B0%E5%88%9B%E4%B8%9A%E5%A4%A7%E8%B5%9B%E6%80%BB%E5%86%B3%E8%B5%9B%E6%9A%A8%E9%A2%81%E5%A5%96%E4%BB%AA%E5%BC%8F.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/316052307205684-%E7%AC%AC%E4%BA%8C%E5%B1%8A%E7%BB%BC%E5%90%88%E4%BA%A4%E9%80%9A%E5%88%9B%E6%96%B0%E5%88%9B%E4%B8%9A%E5%A4%A7%E8%B5%9B%E6%80%BB%E5%86%B3%E8%B5%9B%E6%9A%A8%E9%A2%81%E5%A5%96%E4%BB%AA%E5%BC%8F.jpg',
          desc: '为深入贯彻落实交通强国战略，推进“新基建+综合交通”产业发展，浙江省交通运输厅、中交第一公路勘察设计研究院有限公司等单位联合举办2020第二届综合交通创新创业大赛。大赛旨在聚集整合综合交通产业创新创业资源，孵化培育交通领域新技术、新产品、新模式、新业态，推动技术与市场、资本的产融结合，服务浙江省高水平交通强省建设与中交集团交通强国建设试点单位建设，助力行业构建安全、便捷、高效、绿色、经济的现代化综合交通体系。<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第二届大赛将采取“线上云评审路演+线下现场评审路演”创新模式，积极构建“大赛+人才+项目+资本”协同发展机制，贯彻“一会一馆一园一镇”产业发展战略，联动“一园”、“一馆”作为“一会”产业前沿阵地，搭建企业项目对接、优质产业落地平台。大赛共设置1个国内主赛区和1个海外云赛区，打造“1+1”赛事模式，推动海内外、省市县行业三级联动，多维度聚集优质创新创业项目。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
        {
          title: '航天技术助力交通产业发展论坛',
          guests: [
            ['王一然','中国宇航学会副理事长兼秘书长','主持人'],
            ['王寅中','浙江省交通运输厅副厅长','致辞'],
            ['马涛','中国航天科技集团有限公司宇航部副部长','致辞'],
            ['张旭辉','中国运载火箭技术研究院副院长、国防科工局航天运输领域专家组组长、军委科技委领域专家','全球快速抵达运输系统发展设想与实践'],
            ['谢军','北斗重大专项工程副总设计师、卫星首席总设计师、俄罗斯齐奥尔科夫斯基宇航科学院院士、中国空间技术研究院科技委常委','北斗系统发展及在我国交通领域的应用'],
            ['毛凯','中国航天科工集团有限公司第三研究院磁悬浮与电磁推进技术总体部主任、总师','基于超导的磁悬浮与电磁推进技术在超高速交通领域的应用'],
            ['张拯宁','航天恒星科技有限公司(503所)系统论证中心主任、军委装备发展部专家组成员','通导遥卫星技术在我国交通运输领域的应用'],
            ['朱林崎','国际宇航联合会卫星商业应用专委会亚太办主任、全球航天与电信咨询有限公司CEO','天地往返有航班，助力太空做实验'],
          ],
          video: '',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/216052552028639-%E8%88%AA%E5%A4%A9%E6%8A%80%E6%9C%AF%E5%8A%A9%E5%8A%9B%E4%BA%A4%E9%80%9A%E4%BA%A7%E4%B8%9A%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B.jpg',
          desc: '中国宇航学会将于2020年12月4日第三届浙江国际智慧交通产业博览会期间在杭州举办航天技术助力交通产业发展论坛。本论坛以“航天助力交通产业新动能”为主题，论坛着眼于航天产业化发展、北斗导航卫星全球应用及航天航班化运输的发展目标进行研讨，以发挥航天技术优势，助力交通领域产业创新发展，推动交通产业产出新动能。',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2020/%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87/',
        },
      ],
    }
  }
}
</script>

<style lang="less" scoped>
.first-forum-content {
  min-height: calc(100vh - 565px);
  padding-bottom: 30px;
  .desc {
    color: #666;
    font-size: 16px;
    text-indent: 32px;
    line-height: 25px;
  }
  .live {
    width: 1000px;
    margin: 0 auto;
    .video {
      margin: 20px auto;
      width: 1000px;
      height: 564px;
      border-radius: 5px;
      background: #342DB0;
      box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
    }
  }
  
  .guests-wrap {
    justify-content: flex-start;
    flex-wrap: wrap;
    .guest {
      margin: 8px;
      height: 285px;
      width: 200px;
      border: 1px solid #DDD;
      border-radius: 3px;
      .img {
        height: 230px;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid #DDD;
        img {
          max-height: 230px;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
        .name {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -115px;
          height: 115px;
          background: rgba(0, 0, 0, 0.4);
          transition: all ease-out 0.5s;
          padding: 10px;
          color: #FFF;
          text-align: left;
          font-weight: bold;
          p {
            font-size: 14px;
            // vertical-align: middle;
          }
        }
      }
      h3 {
        font-size: 16px;
        color: #333;
        margin-top: 10px;
        line-height: 30px;
      }
    }
    .guest:hover {
      .name {
        bottom: 0px;
      }
    }
  }
}
</style>
